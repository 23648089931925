@font-face {
  font-family: 'Manrope';
  src: url(./fonts/Manrope-ExtraBold.ttf) format('ttf');
  font-weight: 800;
}
@font-face {
  font-family: 'Manrope';
  src: url(./fonts/Manrope-Bold.ttf) format('ttf');
  font-weight: 700;
}
@font-face {
  font-family: 'Manrope';
  src: url(./fonts/Manrope-SemiBold.ttf) format('ttf');
  font-weight: 600;
}
@font-face {
  font-family: 'Manrope';
  src: url(./fonts/Manrope-Medium.ttf) format('ttf');
  font-weight: 500;
}
@font-face {
  font-family: 'Manrope';
  src: url(./fonts/Manrope-Regular.ttf) format('ttf');
  font-weight: 400;
}
@font-face {
  font-family: 'Manrope';
  src: url(./fonts/Manrope-Light.ttf) format('ttf');
  font-weight: 300;
}

@font-face {
  font-family: 'Manrope';
  src: url(./fonts/Manrope-ExtraLight.ttf) format('ttf');
  font-weight: 200;
}
