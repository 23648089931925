.wrapper {
  max-width: 120.6rem;
  padding-left: 2rem;
  padding-right: 2rem;
  margin: 0 auto;
}

.overflow-break {
  overflow-wrap: break-word;
}
