@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;
@import "style/utils";
@import "style/typography";
@import "style/buttons";

body {
  @apply bg-c-quaternary;

  margin: 0;
  font-family: 'Manrope', 'Arial', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
