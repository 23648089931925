@layer components {
  .close__button {
    position: relative;
    width: 1.6rem;
    height: 1.6rem;
    margin-top: 2rem;

    &:before,
    &:after {
      @apply bg-c-secondary;
      content: '';
      position: absolute;
      top: 1rem;
      left: 0;
      right: 0;
      height: .16rem;
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }
  }
}


